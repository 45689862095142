import './App.css';
import 'devextreme/dist/css/dx.light.css';
import {DataGrid, Column, FilterRow, SearchPanel, GroupPanel, Pager, Paging, Selection} from 'devextreme-react/data-grid'
import axios from 'axios'
import React, {useState, useEffect} from 'react';
import { Container } from '@mui/system';
import { Card, Modal, Box, Typography, Table, TableRow, TableCell } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


const Index = () => {
    const [data, setData] = useState([])
    const [item, setItem] = useState({})
    const [open, setOpen] = useState(false)
    const id=0;
    useEffect(()=>{
      const getData = ()=>{
        const API_URL= process.env.NODE_ENV === 'development'?process.env.REACT_APP_API_URL:window.API_URL;
        axios({
            url:`${API_URL}external/condicionesExamenes`,
            method:'GET'
        }).then(res=>{
            setData(res.data)
        }).catch(err=>{
            alert('Error obteniendo información del servidor')
        })
      }
      getData();
    },[id])

    const onSelectionChanged = ({selectedRowsData})=>{
        setItem(selectedRowsData[0])
        setOpen(true)
    }

    const handleClose = ()=>{
        setOpen(false)
    }

    return ( 
        <Container maxWidth='xl' sx={{marginTop:20}}>            
            <Card>
                <DataGrid id="dataGrid" dataSource={data} showBorders={true} hoverStateEnabled={true} columnAutoWidth={true} allowColumnReordering={true} onSelectionChanged={onSelectionChanged} >
                    <Column dataField={"Tbl1_Id"} visible={false}></Column>
                    <Column dataField={"Tbl1_Cups"} caption="CUPS"></Column>
                    <Column dataField={"Tbl1_Descrip"} caption="DESCRIPCIÓN"></Column>
                    <Column dataField={"Aa_Nombre"} caption="ÁREA"></Column>
                    <Column dataField={"Tbl1_Aa_Id"} visible={false}/>
                    <Column dataField={"Tbl1_Tecnica"} visible={false}/>
                    <Column dataField={"Tbl1_Dias"}  visible={false}/>
                    <Column dataField={"Tbl1_Oportunidad"} visible={false}/>
                    <Column dataField={"Tbl1_Estabilidad"} visible={false}/>
                    <Column dataField={"Tbl1_Recipiente"}  visible={false}/>
                    <Column dataField={"Tbl1_Muestra"} visible={false}/>
                    <Column dataField={"Tbl1_Prerequisitos"}  visible={false}/>
                    <Column dataField={"Tbl1_Ayuno"} visible={false}  />     
                    <Selection mode="single" />
                    <GroupPanel visible={true} />
                    <FilterRow visible={true} />
                    <SearchPanel visible={true} />   
                    <Paging defaultPageSize={40} />   
                    <Pager  visible={true} allowedPageSizes={true} displayMode={'full'} showPageSizeSelector={true} showInfo={true} showNavigationButtons={true} />
                </DataGrid>
                <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description" >
                    <Box sx={{ ...style }}>
                        <h2 id="parent-modal-title">Detalle</h2>
                        <Table size='small'>
                            <TableRow><TableCell><Typography variant='button'>CUPS: </Typography></TableCell><TableCell>{item.Tbl1_Cups && <Typography variant='button' component={'h6'}>{item.Tbl1_Cups} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>DESCRIPCIÓN: </Typography></TableCell><TableCell>{item.Tbl1_Descrip && <Typography variant='button' component={'h6'}>{item.Tbl1_Descrip} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>ÁREA: </Typography></TableCell><TableCell>{item.Aa_Nombre && <Typography variant='button' component={'h6'}>{item.Aa_Nombre} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>TÉCNICA: </Typography></TableCell><TableCell>{item.Tbl1_Tecnica && <Typography variant='button' component={'h6'}>{item.Tbl1_Tecnica} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>DÍAS: </Typography></TableCell><TableCell>{item.Tbl1_Dias && <Typography variant='button' component={'h6'}>{item.Tbl1_Dias} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>OPORTUNIDAD END DÍAS: </Typography></TableCell><TableCell>{item.Tbl1_Oportunidad && <Typography variant='button' component={'h6'}>{item.Tbl1_Oportunidad} días hábiles </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>ESTABILIDAD: </Typography></TableCell><TableCell>{item.Tbl1_Estabilidad && <Typography variant='button' component={'h6'}>{item.Tbl1_Estabilidad} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>RECIPIENTE: </Typography></TableCell><TableCell>{item.Tbl1_Recipiente && <Typography variant='button' component={'h6'}>{item.Tbl1_Recipiente} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>MUESTRA: </Typography></TableCell><TableCell>{item.Tbl1_Muestra && <Typography variant='button' component={'h6'}>{item.Tbl1_Muestra} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>PRE REQUISITOS: </Typography></TableCell><TableCell>{item.Tbl1_Prerequisitos && <Typography variant='button' component={'h6'}>{item.Tbl1_Prerequisitos} </Typography>}</TableCell></TableRow>
                            <TableRow><TableCell><Typography variant='button'>REQUIERE AYUNO?: </Typography></TableCell><TableCell>{item.Tbl1_Ayuno? <Typography variant='button' component={'h6'}>SI</Typography>:<Typography variant='button' component={'h6'}>NO</Typography>}</TableCell></TableRow>
                        </Table>
                    </Box>
                </Modal>   
            </Card>         
        </Container>
     );
}
 
export default Index;